<template>
  <div :class="{mainTable: table === 'main', moreTable: table ==='more'}">
    <ul v-if="table === 'main'">
      <li>{{$t('table.head.memIdNick')}}</li><!-- class="w170" -->
      <li class="name-img">{{$t('table.head.bankname')}}(<img src="@/assets/img/icon_memos.svg" />)</li>
      <li>{{$t('table.head.level')}}</li>
      <li>{{$t('table.head.upper')}}</li>
      <li>{{ $t('common.rolling') }}/{{ $t('common.losing') }}<span>({{$t('table.head.casino')}})</span></li>
      <li>{{ $t('common.lowmoneytrans') }}<br>({{ $t('button.payment') }}/{{ $t('common.coll') }})</li>
      <li>{{$t('table.head.subPartnerCnt')}}<br>{{$t('table.head.haveUserNum')}}</li>
      <li>{{$t('table.body.money')}}</li>
      <li>{{$t('table.head.pointAmt')}}</li>
      <li>{{$t('table.head.bMoney')}}<br>{{$t('table.head.bPT')}}</li>
      <li>{{$t('header.inoutAmt')}}</li>
      <li>{{$t('table.head.betwlt')}}</li>
      <li>{{$t('table.head.subct')}}</li>
      <li>{{$t('table.head.userCreate')}}</li>
      <li>{{$t('common.cashHy')}}</li>
      <li>{{$t('button.bMemo')}}</li>
    </ul>
    <template v-if="list.length > 0" >
      <partner-tr v-for="item in list" :data="item" :reqData="reqData" :level="level" :key="item.memId" :currentTab="currentTab" :rateCategory="rateCategory"/>
    </template>
  </div>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import { mapState } from 'vuex'
import PartnerTr from '@/components/ui/partner/partnerTr2.vue'

export default {
  name: 'partnerTable',
  inheritAttrs: false,
  data () {
    return {
      onInfo: false
    }
  },
  components: {
    PartnerTr,
    TableHead
  },
  props: [
    'table',
    'headInfo',
    'list',
    'reqData',
    'level',
    'rateCategory',
    'currentTab'
  ],
  computed: {
    ...mapState([
      'gameGroupList'
    ]),
    currentTabCodeName () {
      const selectedTab = this.rateCategory.find(item => item.cateCode === this.currentTab)
      return selectedTab ? selectedTab.cateCodeName : ''
    }
  },
  methods: {
    transTab (type) {
      this.$emit('onChangeTab', type)
    },
    showInfo () {
      this.onInfo = true
    },
    hideInfo () {
      this.onInfo = false
    },
    getCategoryNameText (categoryName) {
      if (categoryName === 'HC-카지노') {
        return '호텔 카지노'
      }
      return categoryName
    }
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w170 {width: 170px !important;}
.w70 {width: 70px;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.moreTable {width: 100%;}
.moreTable .moreTable {border: 0;}
.moreTable td {background: #fff3f3 !important;}
.moreTable .moreTable td {background: #e8ffe9 !important;}
.moreTable .moreTable .moreTable td {background: #d8eaf5 !important;}
.moreTable .moreTable .moreTable .moreTable td {background: #fffee1 !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable td {background: #e1fffb !important;}
.moreTable .moreTable .moreTable .moreTable .moreTable .moreTable td {background: #ffe1e1 !important;}

.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {justify-content: center;display: flex;align-items: center;}
.morebtn a {cursor: pointer;}

.mainTable ul {display: flex;box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);border: solid 1px #e0e0e0;background-color: #fbfbfb;color: #585963;}
.mainTable ul li {/*width: 100%;*/height: 50px;font-size: 13px;text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);display: flex;align-items: center;justify-content: center;flex-direction: column;}
.mainTable li:nth-child(1) {width: 12%;}
.mainTable li:nth-child(2) {width: 5%;}
.mainTable li:nth-child(3) {width: 3%;}
.mainTable li:nth-child(4) {width: 9%;}
.mainTable li:nth-child(5) {width: 7%;}
.mainTable li:nth-child(6) {width: 7%;}
.mainTable li:nth-child(7) {width: 5%;}
.mainTable li:nth-child(8) {width: 8%;}
.mainTable li:nth-child(9) {width: 8%;}
.mainTable li:nth-child(10) {width: 10%;}
.mainTable li:nth-child(11) {width: 6%;}
.mainTable li:nth-child(12) {width: 6%;}
.mainTable li:nth-child(13) {width: 3.5%;}
.mainTable li:nth-child(14) {width: 3.5%;}
.mainTable li:nth-child(15) {width: 3.5%;}
.mainTable li:nth-child(16) {width: 3.5%;}
.mainTable ul li:first-child a {/*width: 200px;*/}
.mainTable ul .name-img {flex-direction: row;}

.selectWrap {
  display: flex;
  gap: 5px;
  align-items: center;
}
.tabwrap{
  display: flex;
  gap: 5px;
  margin: 10px 0;
}
.tabwrap li {
  padding: 5px 8px;
  border-radius: 5px;
  background: #e4efee;
  color: #82a6a4;
  border: 1px solid #82a6a4;
  font-size: 14px;
  cursor: pointer;
}
.tabwrap li.color {
  background: #ffffff;
}
.tabwrap li.select {
  background: #82a6a4;
  color: #fff;
}
.infoicon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #82a6a4;
  color: #82a6a4;
  background: #e4efee;
  cursor:pointer;
}
.infobox{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #82a6a4;
  color: #82a6a4;
  background: #e4efee;
  font-size: 13px;
}
</style>
