<template>
  <ul class="partner">
    <li class="morebtn"><!-- w170 {{data.cntBotPartner}}{{data.cntBotMem}} -->
      <template v-if="level > 0 && reqData.partnerLevel != ''" >
        <a @click="getMore" v-if="Number(data.cntBotPartner)" class="plus">
          <template v-if="level">
            <i class="fa" :class="[ inSearchList.length > 0 ? 'fa-minus' : 'fa-plus' ]" aria-hidden="true"></i>
          </template>
        </a>
        <template v-else>
          <span class="else"><i class="fa">•</i></span>
        </template>
      </template>
      <a @click="detailOpen('partner', data.memId)" class="pid">
        {{data.memId}}
        <span class="fc-nick">{{data.memNick}}</span>
      </a>
    </li>
    <li>
      <a @click="openMemberPopup(data, 'isOpenMemo')" style="cursor: pointer;">{{data.memName}}</a>
      <div class="memo-box-wrap" v-if="isOpenMemo">
        <div class="memo-box-in">
          <div class="memo-box-title">Memo</div>
          <div class="memo-box-content">
            <textarea :placeholder="$t('txt.enterNote')" v-model="selectMember.memo"> {{selectMember}}</textarea>
          </div>
        </div>
        <div class="memo-box-btn">
          <a @click="setMemo(selectMember)">{{ $t('searchArea.modify') }}</a>
          <a @click="isOpenMemo = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
        </div>
      </div>
    </li>
    <li :class="data.partnerLevel">
      <template v-if="data.levelName === '총본사'">
        <span class="box">{{ $t('common.seniorA') }}</span>
      </template>
      <template v-else-if="data.levelName === '대본사'">
        <span class="box">{{ $t('common.masterA') }}</span>
      </template>
      <template v-else-if="data.levelName === '부본사'">
        <span class="box">{{ $t('common.juniorA') }}</span>
      </template>
      <template v-else>
        <span class="box">{{data.levelName}}</span>
      </template>
    </li>
    <li>
      <div class="topwrap" v-if="data.topUserList.length > 0">
        <select @change="onDetailOpen" v-if="data.topUserList" :class="data.partnerLevel" class="select">
          <template v-if="data.topUserList">
            <template v-for="(user, index) in data.topUserList" :key="user.recommenderId">
              <option :value="user.recommenderId" class="option" :class="user.partnerLevel">
                <span class="topbox">[ {{computedPartnerLevel(user)}} ] </span>
                <span :class="user.partnerLevel">{{user.recommenderId}}</span>
              </option>
            </template>
          </template>
        </select>
        <i class="icon">+</i>
      </div>
    </li>
    <li>
      <div class="btn-inout-wrap w100 roboto">
         {{data.pointRate}}% / {{data.loseRate}}%
         <!-- <i @click="modalActive=!modalActive" class="more ml-5">+</i> -->
         <i @click="modalOn(data)" class="more">+</i>
      </div>
    </li>
    <li class="gap-10">
      <input type="checkbox" class="set-switch" :class="{'on': data.cashSendYn === 'Y'}" @click="switchToggle(data, 'cashSendYn')" :checked="data.cashSendYn === 'Y'"/>
      <input type="checkbox" class="set-switch" :class="{'on': data.cashReceiveYn === 'Y'}" @click="switchToggle(data, 'cashReceiveYn')" :checked="data.cashReceiveYn === 'Y'"/>
    </li>
    <li class="gap-3"><span class="roboto">{{ numberWithCommas(data.cntBotPartner) }}</span>/<span class="roboto">{{ numberWithCommas(data.cntBotMem) }}</span></li>
    <li>
      <div class="btn-inout-wrap w120">
        <span class="haveM roboto">{{ numberWithCommas(data.cashAmt) }}</span>
        <a @click="openMemberPopup(data, 'isOpenCash')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
      </div>
    </li>
    <li>
      <div class="btn-inout-wrap w120 roboto">
        <span class="haveM roboto">{{ numberWithCommas(data.pointAmt) }}</span>
        <a @click="openMemberPopup(data, 'isOpenPoint')" class="btn-inout">{{ $t('searchArea.listTypeB') }}</a>
      </div>
    </li>
    <li>
      <div class="money">
       <div><em>{{ $t('common.lowmoney') }}: </em><span class="fc-nick">{{ numberWithCommas(data.totalInfo.botCashAmt) }}</span></div>
       <div><em>{{ $t('common.lowPt') }}: </em><span>{{ numberWithCommas(data.totalInfo.botPointAmt) }}</span></div>
      </div>
    </li>
    <li class="roboto" :class="{'fc-red': data.totalInfo.totalCashInOut < 0}">
      {{ numberWithCommas(data.totalInfo.totalCashInOut) }}
    </li>
    <li class="roboto" :class="{'fc-red': data.totalInfo.totalWinLose < 0}">
      {{ numberWithCommas(data.totalInfo.totalWinLose) }}
    </li>
    <li>
      <a class="btn-inout bg-pk" v-if="data.partnerLevel !== 'PTN_5'" @click="goToPageNamePopup('/partner/partnerCreate', data, 'partnerCreate')">{{ $t('table.head.subct') }}</a><!-- goToPageNameOpen('partnerCreate', data) -->
    </li>
    <li>
      <a class="btn-inout bg-gn" @click="goToPageNamePopup('/user/Create', data, 'userCreate')">{{ $t('user.userCreate') }}</a><!-- goToPageName('userCreate', data) -->
    </li>
    <li>
      <a class="btn-inout bg-pk" @click="goToPageNamePopupb('/user/cash/cashMoveHistory', data, 'CashMoveHistory')">{{ $t('betting.move') }}</a>
    </li><!-- parentLinkMove('/user/cash/cashMoveHistory', data.memId) -->
    <li>
      <a class="btn-inout bg-rd" @click="msgOpen = true">{{ $t('common.sendMsg') }}</a>
    </li>
  </ul>
  <ul v-if="inSearchList.length > 0" class="partner">
    <li class="bottom">
      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :currentTab="currentTab"
                 :list="inSearchList"
                 :reqData="reqData"
                 :level="level + 1"
                 :rateCategory="rateCategory"
      />
    </li>
  </ul>

  <div class="modalWrap" v-if="modalActive">
    <div class="modal-item modal2">
      <article class="modal-title">
        <h2>
          {{ $t('table.head.rateList') }}
          <a @click="modalActive2=!modalActive2">{{ $t('table.head.rateSet') }}</a>
        </h2>
        <button class="btn-close" type="button" @click="modalActive=!modalActive"><i class="fa fa-times" aria-hidden="true"></i></button>
      </article>
      <div class="modal-body">
        <table class="rate-table">
          <colgroup>
            <col width='38%' />
            <col width='31%' />
            <col width='31%' />
          </colgroup>
          <template v-for="category in data.rateInfoList" :key="category.gameCategory">
            <tr>
              <th>{{ category.gameCategoryName }}</th>
              <td>
                <span class="rate">{{ $t('common.rolling') }}: {{ category.pointRate }}%</span>
              </td>
              <td>
                <span class="rate">{{ $t('common.losing') }}: {{ category.loseRate }}%</span>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>

  <div class="modalWrap" v-if="modalActive2">
    <div class="modal-item">
      <article class="modal-title">
        <h2>{{ $t('common.rateSet') }}<span>{{ data.memId }}</span>(<em>{{ data.memNick }}</em>)</h2>
        <button class="btn-close" type="button" @click="modalActive2=!modalActive2"><i class="fa fa-times" aria-hidden="true"></i></button>
      </article>
      <div class="modal-body">
        <table class="rate-table">
          <colgroup>
            <col width='21%' />
            <col width='35%' />
            <col width='35%' />
            <col width='9%' />
          </colgroup>
          <thead>
          <tr>
            <th>{{ $t('table.head.gameName') }}</th>
            <th>{{ $t('searchArea.rollSet') }}</th>
            <th>{{ $t('searchArea.loseSet') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="category in data.rateInfoList" :key="category.gameCategory">
            <tr>
              <th>{{ category.gameCategoryName }}</th>
              <td>
                 <span class="rate">
                  {{ $t('common.rolling') }}:
                    <input type="text" :class="{'error': category.errorP}" v-model="category.pointRate" @change="onRateChange(category, 'P')"> %
                 </span>
                <span class="rate ml-10">*{{ $t('table.head.min') }}:{{ category.minPointRate }} ~ {{ $t('table.head.max') }}:{{ category.maxPointRate }}</span>
              </td>
              <td>
              <span class="rate">
                {{ $t('common.losing') }}:
                 <input type="text" :class="{'error': category.errorR}" v-model="category.loseRate" @change="onRateChange(category, 'R')"> %
              </span>
                <span class="rate ml-10">*{{ $t('table.head.min') }}:{{ category.minLoseRate }} ~ {{ $t('table.head.max') }}:{{ category.maxLoseRate }}</span>
              </td>
              <td><a class="s-btn" @click="onChangeNewRate(data, category)">{{ $t('common.save') }}</a></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal-wrapper" v-if="isOpenCash">
    <div class="modal-wrap2">
      <div class="sub-title2">
        <h2>{{ $t('txt.memberManager') }}</h2>
        <h4>{{ getDomain() }}</h4>
        <a class="btn" @click="isOpenCash = false;selectMember = null"><i class="fa fa-times"></i></a>
      </div>
      <div class="modal-content2">
        <ul class="modal-info">
          <li>
            <span>{{ $t('common.id') }} :</span>
            <span>{{ selectMember.memId }}</span>
          </li>
          <li>
            <span>{{ $t('common.nickName') }} :</span>
            <span class="fc-org">{{ selectMember.memNick }}</span>
          </li>
          <li>
            <span>{{ $t('txt.currentCash') }} :</span>
            <span>{{ numberWithCommas(selectMember.cashAmt) }}</span>
          </li>
          <li>
            <span>{{ $t('table.head.aftM') }} :</span>
            <span class="onlyNum">
                  <input id="newCash" class="input" type="number" v-model="selectMember.newCash"/>
                  <label for="newCash">{{ numberWithCommas(selectMember.newCash || 0) }}</label>
                </span>
          </li>
        </ul>
        <div class="modal-btns">
          <a @click="setCash(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
          <a @click="setCash(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-wrapper" v-if="isOpenPoint">
    <div class="modal-wrap2">
      <div class="sub-title2">
        <h2>{{ $t('txt.memberManagerpoint') }}</h2>
        <h4>{{ getDomain() }}</h4>
        <a class="btn" @click="isOpenPoint = false;selectMember = null"><i class="fa fa-times"></i></a>
      </div>
      <div class="modal-content2">
        <ul class="modal-info">
          <li>
            <span>{{ $t('common.id') }} :</span>
            <span>{{ selectMember.memId }}</span>
          </li>
          <li>
            <span>{{ $t('common.nickName') }} :</span>
            <span class="fc-org">{{ selectMember.memNick }}</span>
          </li>
          <li>
            <span>{{ $t('txt.currentPoint') }} :</span>
            <span>{{ numberWithCommas(selectMember.pointAmt) }}</span>
          </li>
          <li>
            <span>{{ $t('table.head.aftPT') }} :</span>
            <span class="onlyNum">
                <input id="newPoint" class="input" type="number" v-model="selectMember.newPoint"/>
                <label for="newPoint">{{ numberWithCommas(selectMember.newPoint || 0) }}</label>
             </span>
          </li>
        </ul>
        <div class="modal-btns">
          <a @click="setPoint(selectMember, 'in')">{{ $t('searchArea.payment') }}</a>
          <a @click="pointChange(selectMember)">{{ $t('searchArea.trans') }}</a>
          <a @click="setPoint(selectMember, 'out')" class="btn">{{ $t('searchArea.retrieval') }}</a>
        </div>
      </div>
    </div>
  </div>
  <msg-modal v-if="msgOpen" :boardType="'msgList'" :recieveId="data.memId" :targetType="'member'" @modalClose="modalClose"></msg-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import {
  partnerNewListb,
  partnerRateInfoList,
  partnerRateInfoSave,
  partnerSettingCashInOut
} from '@/api/partner'
import msgModal from '@/components/common/msgModal'
import Select from '../Select.vue'
import { ca } from 'date-fns/locale'
import {
  cashIn,
  cashOut,
  memberPointInOut,
  memberToAdminPoint
} from '@/api/member.js'
import { TOP_PARTNER_LEVEL } from '@/libs/constants'

export default {
  components: {
    msgModal
  },
  name: 'partnerTr',
  inheritAttrs: false,
  props: [
    'data',
    'reqData',
    'level',
    'currentTab',
    'rateCategory'
  ],
  computed: {
    ca () {
      return ca
    },
    ...mapState([
      'gameGroupList',
      'siteOption'
    ]),
    component () {
      return defineAsyncComponent(() => import('@/components/ui/partner/partnerTable2.vue'))
    }
  },
  mounted () {
  },
  data () {
    return {
      more: false,
      inSearchList: [],
      loader: false,
      modalActive: false,
      modalActive2: false,
      isOpenCash: false,
      isOpenPoint: false,
      isOpenMemo: false,
      selectMember: null,
      msgOpen: false
    }
  },
  created () {

  },
  watch: {
    data () {
      this.inSearchList = []
      this.more = false
      this.loader = false
    }
  },
  methods: {
    async modalOn (item) {
      this.modalActive = !this.modalActive
      const req = {
        memId: item.memId
      }
      partnerRateInfoList(req).then(res => {
        console.log(res)
        if (res.resultCode === '0') {
          item.rateInfoList = res.data.rateInfoList
        }
      })
    },
    getDomain () {
      return location.host
    },
    modalClose (value) {
      this.msgOpen = value
    },
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
    },
    parentLinkMove (linkTarget, memId) {
      window.open(`${linkTarget}?id=${memId}`, '_blank')
    },
    async setMemo (item) {
      if (!item.memo) {
        alert('내용을 입력해주세요.')
      } else {
        const req = {
          memId: item.memId,
          memo: item.memo
        }
        c
        if (res.resultCode === '0') {
          alert('메모 입력 완료')
        } else {
          alert('메모입력 실패, 다시 시도해주세요.')
        }
        this.isOpenMemo = false
      }
    },
    pointChange (user) {
      const params = {
        siteId: this.siteOption.siteId,
        memId: user.memId,
        pointAmt: Number(this.selectMember.newPoint)
      }

      if (confirm('포인트 전환 하시겠습니까?')) {
        this.emitter.emit('Loading', true)
        memberToAdminPoint(params).then(res => {
          console.log(res)
          if (res.resultCode === '0') {
            this.selectMember.pointAmt = this.selectMember.pointAmt - Number(this.selectMember.newPoint)
            this.selectMember.newPoint = ''
            alert('포인트 전환 되었습니다.')
          } else {
            alert(res.resultMessage)
          }
          this.emitter.emit('Loading', false)
        })
        this.isOpenPoint = false
      }
    },
    async setPoint (item, type) {
      let res = ''
      if (!item.newPoint) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            pointType: 2,
            pointDesc: '관리자 포인트 지급',
            pointAmt: item.newPoint,
            memo: ''
          }
          if (confirm('지급처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.pointAmt = item.pointAmt + item.newPoint
            } else if (res.resultCode === 'P006') {
              alert('포인트지급,회수 처리는 1분에 1건씩 처리됩니다. 잠시후 다시 시도해주세요')
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          if (item.pointAmt < item.newPoint) {
            model.amt = 0
            alert('회수포인트는 보유포인트보다 적어야 합니다.')
            return
          }
          const req = {
            memId: item.memId,
            pointType: -2,
            pointDesc: '관리자 포인트 회수',
            pointAmt: item.newPoint * -1,
            memo: ''
          }
          if (confirm('회수처리 하시겠습니까?')) {
            res = await memberPointInOut(req)
            if (res.resultCode === '0') {
              alert('회수완료')
              item.pointAmt = item.pointAmt - item.newPoint
            } else {
              alert('회수실패, 다시 시도해주세요.')
            }
          }
        }
      }
    },
    async setCash (item, type) {
      if (!item.newCash) {
        alert('금액을 입력해주세요.')
      } else {
        if (type === 'in') {
          const req = {
            memId: item.memId,
            inAmt: item.newCash,
            memo: '',
            type: '',
            inBonusYn: 'N'
          }
          if (confirm('지급처리하시겠습니까?')) {
            const res = await cashIn(req)
            if (res.resultCode === '0') {
              alert('지급완료')
              item.cashAmt = item.cashAmt + item.newCash
              return
            } else {
              alert('지급실패, 다시 시도해주세요.')
            }
          }
        } else {
          const req = {
            memId: item.memId,
            outAmt: item.newCash,
            memo: '',
            type: '',
            bonusRollingYn: 'N'
          }
          if (confirm('회수처리하시겠습니까?')) {
            const res = await cashOut(req)
            if (res.resultCode === '0') {
              alert('회수 완료')
              item.cashAmt = item.cashAmt - item.newCash
              return
            } else {
              if (res.resultCode === 'C702') {
                alert('회수할 금액이 유저 보유금보다 많을 수 없습니다.')
              } else if (res.resultCode === 'C099') {
                alert(res.resultMessage)
              } else {
                alert('회수실패, 다시 시도해주세요.')
              }
            }
          }
        }
        item.newCash = ''
        this.isOpenCash = false
      }
    },
    computedPartnerLevel (user) {
      return TOP_PARTNER_LEVEL[user.partnerLevel]
    },
    onDetailOpen (event) {
      const selectedRecommenderId = event.target.value
      this.detailOpen('partner', selectedRecommenderId)
    },
    onRateChange (item, type, category) {
      if (category) {
        category['error' + type] = false
      } else {
        item['error' + type] = false
      }

      const max = type === 'P' ? item.maxPointRate : item.maxLoseRate
      const min = type === 'P' ? item.minPointRate : item.minLoseRate
      const value = type === 'P' ? item.pointRate : item.loseRate

      if (Number(value) > Number(max)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
        console.log('!!!')
      } else if (Number(min) > Number(value)) {
        if (category) {
          category['error' + type] = true
        } else {
          item['error' + type] = true
        }
      }
    },
    switchToggle (item, key) {
      if (item[key] === 'Y') {
        item[key] = 'N'
      } else {
        item[key] = 'Y'
      }
      console.log(item[key])
      const param = {
        memId: item.memId,
        setType: key === 'cashSendYn' ? 'SEND' : 'RECEIVE',
        setYn: item[key]
      }
      partnerSettingCashInOut(param).then(res => {
        console.log(res)
      })
    },
    async onChangeNewRate (item, cate) {
      console.log(item, cate)
      this.emitter.emit('Loading', true)

      if (!cate.errorP && !cate.errorR) {
        const miniParam = {
          memId: cate.memId,
          gameCategory: cate.gameCategory,
          gameCategoryEn: cate.gameCategoryEn,
          vendorCode: cate.vendorCode,
          rateTypeCode: cate.rateTypeCode,
          pointRate: cate.pointRate,
          loseRate: cate.loseRate
        }

        partnerRateInfoSave(miniParam).then(res => {
          this.emitter.emit('Loading', false)
          if (res.resultCode === '0') {
            alert('저장 완료되었습니다.')
          } else {
            alert(res.resultMessage)
          }
        })
      } else {
        this.emitter.emit('Loading', false)
        alert('요율을 다시 확인해주세요.')
      }
    },
    goToPageName (name, item) {
      this.$router.push({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
    },
    goToPageNameOpen (name, item) {
      const routeData = this.$router.resolve({ name, query: { partnerLevel: item.partnerLevel, partnerId: item.memId } })
      window.open(routeData.href, '_blank')
    },
    goToPageNamePopup (linkTarget, item, name) {
      const size = {
        width: 1920,
        height: 1000
      }
      const position = {
        top: 100,
        left: Math.ceil((window.screen.width - size.width) / 2)
      }
      window.open(`${linkTarget}?partnerLevel=${item.partnerLevel}&partnerId=${item.memId}`, `${name}`, `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    goToPageNamePopupb (linkTarget, item, name) {
      const size = {
        width: 1920,
        height: 1000
      }
      const position = {
        top: 100,
        left: Math.ceil((window.screen.width - size.width) / 2)
      }
      window.open(`${linkTarget}?id=${item.memId}`, `${name}`, `top=${position.top},left=${position.left},scrollbars=no,resizable=no,width=${size.width},height=${size.height},status=no`)
    },
    async getMore () {
      this.emitter.emit('Loading', true)
      console.log(this.inSearchList)
      if (this.inSearchList.length === 0) {
        const reqData = { ...this.reqData }
        const memId = this.data.memId
        reqData.searchType = 'INNER'
        reqData.memId = memId
        reqData.page = 1

        const res = await partnerNewListb(reqData)
        console.log('partnerListB ::: ', res)
        if (res.resultCode === '0') {
          console.log(res)
          this.inSearchList = res.data.list

          this.inSearchList.forEach(item => {
            console.log(item)
            /* if (item.memRollingInfo.length > 0) {
              item.memRollingInfo.forEach(cate => {
                cate.errorP = false
                cate.errorR = false
              })
           } */
          })

          setTimeout(function () {
            this.loader = true
          }.bind(this), 500)
        }
      } else {
        this.inSearchList = []
      }
      this.emitter.emit('Loading', false)
    }
  }
}
</script>

<style scoped>
.p0 {padding: 0;}
.w120 {width: 120px !important;}
.w170 {width: 170px !important;}
.w45 {width: 45px;padding: 5px;}
.w70 {width: 70px;}
.haveM {font-size: 15px;font-weight: 500;}
.error {border-color: red;}
.btn-stairOpen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonPositionWrap {
  display: flex;
  justify-content: center;
}
.searchBar > * {
  margin-right: 5px;
}
.searchBar > *:last-child {
  margin-right: 0;
}
.memIdRow {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
}
.btn-childOpen {
  position: relative;
}
.memIdRow span {
  position: relative;
  white-space: nowrap;
}
.modal-info li {width: 100% !important;}

.money > div:first-child {margin-top: 0;}
.money > div {display: flex;justify-content: space-between;align-items: center;margin-top: 7px;}
.money em {width: 65px;text-align: right;font-size: 12px;}
.money span {width: 80px;text-align: right;display: inline-block;font-family: 'Roboto';font-size: 14px;}

.moreTable {width: 100%;margin-top: -5px;}
.moreTable .moreTable {border: 0;}
.pid {box-sizing: border-box;padding-left: 5px;background: #fff;height: 50px !important;/*min-width: 170px;*/width: 100%;border-bottom: 1px solid #e1e0e0;display: flex;flex-direction: column;justify-content: center;align-items: start;gap: 3px;}
.partner .partner .morebtn {min-width: 145px;}
.partner .partner .partner .morebtn {min-width: 160px;}
.partner .partner .partner .partner .morebtn {min-width: 180px;}
.partner .partner .partner .partner .partner .morebtn {min-width: 200px;}

.partner li:first-child {justify-content: left;}
.partner li:first-child .morebtn > a:first-child,
.partner li:first-child .morebtn .else {margin-left: 20px;}
.partner .partner li:first-child .morebtn > a:first-child,
.partner .partner li:first-child .morebtn .else {margin-left: 40px;}
.partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner li:first-child .morebtn .else {margin-left: 60px;}
.partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 80px;}
.partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 100px;}
.partner .partner .partner .partner .partner .partner li:first-child .morebtn > a:first-child,
.partner .partner .partner .partner .partner .partner li:first-child .morebtn .else {margin-left: 120px;}

.mainTable tbody:last-child .moreTable tr:last-child {border-bottom: 0;}
.morebtn {display: flex;align-items: center;}
.morebtn a {cursor: pointer;}
.morebtn a:first-child {justify-content: center;}
.morebtn a:first-child,
.morebtn .else {padding-right: 13px;height: 50px;display: flex;align-items: center;}
.morebtn a .fa,
.else .fa {color: #fff;border-radius: 2px;padding: 0;background: #696969;width: 14px;height: 14px;display: flex;align-items: center;justify-content: center;font-size: 12px;}

.morebtn i::after {content: '';background: #fff;width: 14px;height: 14px;position: absolute;left: 14px;}

td a {cursor: pointer;display: flex;align-items: center;justify-content: center;color: #0a877b;}

/*.partner li .rate {display: flex;flex-direction: column;align-items: baseline;font-size: 10px;margin-left: 3px;width: 58px;}
.partner li .rate .min {color: #eb0000;}
.partner li .rate .max {color: #000fdf;}*/

.partner {display: flex;margin-top: 3px;}
.partner > li {/*width: 100%;font-size: 13px;*/min-height: 50px;background: #fff;color: #898989;display: flex;align-items: center;justify-content: center;border-bottom: solid 1px #e1e0e0;box-sizing: border-box;position: relative;}
.partner > li.bottom {width: 100% !important;}
.partner > li > a {color: #898989;}

.partner >>> li:nth-child(1) {width: 12%;}
.partner >>> li:nth-child(2) {width: 5%;}
.partner >>> li:nth-child(3) {width: 3%;}
.partner >>> li:nth-child(4) {width: 9%;}
.partner >>> li:nth-child(5) {width: 7%;}
.partner >>> li:nth-child(6) {width: 7%;}
.partner >>> li:nth-child(7) {width: 5%;}
.partner >>> li:nth-child(8) {width: 8%;}
.partner >>> li:nth-child(9) {width: 8%;}
.partner >>> li:nth-child(10) {width: 10%;}
.partner >>> li:nth-child(11) {width: 6%;}
.partner >>> li:nth-child(12) {width: 6%;}
.partner >>> li:nth-child(13) {width: 3.5%;}
.partner >>> li:nth-child(14) {width: 3.5%;}
.partner >>> li:nth-child(15) {width: 3.5%;}
.partner >>> li:nth-child(16) {width: 3.5%;}
.partner li:first-child {background: none;border: 0;}
.partner .morebtn {position: relative;}
.partner li:first-child .plus,
.partner li:first-child .morebtn .else {position: relative;}
.partner li:first-child .plus:first-child::before,
.partner li:first-child .else::before {
  content: '';
  width: 2px;
  height: 45px;
  background: #ebebeb;
  position: absolute;
  left: 6px;
  top: 32px;
}
.partner li:first-child .plus:first-child::after,
.partner li:first-child .else::after {
  content: '';
  width: 12px;
  height: 2px;
  background: #ebebeb;
  position: absolute;
  left: 8px;
  top: 75px;
}

.NORMAL {color: #6d409d;}
.PTN_1 {color: #2b476e;}
.PTN_2 {color: #363b72;}
.PTN_3 {color: #1d505c;}
.PTN_4 {color: #af2a2e;}
.PTN_5 {color: #d98f00;}
.box {width: 38px;border: solid 1px #000;border-radius: 2px;padding: 3px 4px;box-sizing: border-box;color: #fff;font-size: 12px;}
.NORMAL .box {background: #6d409d;}
.PTN_1 .box {background: #2b476e;}
.PTN_2 .box {background: #363b72;}
.PTN_3 .box {background: #1d505c;}
.PTN_4 .box {background: #af2a2e;}
.PTN_5 .box {background: #d98f00;}

.modal-title {
  font-size: 13px;
  padding: 5px 14px;
  border: solid 1px #b1b1b1;
  background-color: #c8d4e4;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-title h2 {display: flex;align-items: center;}
.modal-title h2 span {color: #876a97;margin: 0 5px;}
.modal-title h2 em {color: #a55200;}
.modal-title h2 a {border-radius: 4px;box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);border: solid 1px #616161;background-color: #f3efea;font-size: 11px;color: #525976;padding: 3px 5px;cursor: pointer;}
.modal-title .PTtabmenu {
}

.modal-title .PTtabmenu li.current {
  font-weight: bold;
  box-sizing: border-box;
}

.sub-title {
  color: #fff;
  font-size: 18px;
  border-bottom: 0;
  justify-content: center;
}

.modal-item {
  width: 650px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: #5c5e60;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #b1b1b1;
  background-color: #fff;
  box-sizing: border-box;
}
.modal-item.modal2 {width: 350px;background: #c8d4e4;border-radius: 10px;padding: 0 5px 5px;}
.modal-item.modal2 .modal-title {border: 0;}
.modal-item.modal2 .modal-title h2 {gap: 10px;}
.modal-item .btn-close {background: transparent;color: #3d3d3d;}
.modal-close {
  transform: translate(-50%, 100%);
}
.modal-item .btn-layout {
  padding: 8px 15px;
  font-size: 16px
}
.ui-check-wrap {
  width: fit-content;
  margin-right: 5px;
}

.btn-wrap {
  gap: 10px;
  width: 100%;
}
.modalWrap .item-title {
  display: flex;
  align-items: center;
  min-width: 100px;
}

.modalWrap .item-title + input {
  text-align: right;
}

.modalWrap .fx-item-auto {
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #d5d5d5;
}

.modal-body input {
  color: #585963;
  text-align: center;
  font-size: 10px;
  width: 40px;
  height: 20px;
  padding: 0 3px;
  border-radius: 2px;
  border: solid 1px #898989;
  background-color: #fff;
  box-sizing: border-box;
}

.modal-body input[type="text"]:disabled {
  border: 0;
  color: #292a2c;
}

.modal-body select {
  background: transparent;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
  height: auto;
  padding: 5px 15px;
  width: 49%;
}

.modalNotice {
  color: red;
  font-size: 0.8em;
  font-weight: 700;
}
.modal-body .accountInfo {display: flex; gap: 1%;}
.modal-body .accountInfo input { width: 50%; }
.part{
  width: 100%;
  margin-bottom: 20px;
}
.part td{
  background: none;
  border: 0;
  color: #fff;
}
.part td, .part th {
  border: 1px solid #505050;
  padding: 10px 20px;
}
.part2 input {width: 30%;}
.more {width: 14px;height: 14px;background: #696969;border-radius: 2px;color: #fff;font-size: 17px;display: flex;align-items: center;justify-content: center;cursor: pointer;}

/* modal */
.rate-table {border: 1px solid #c8d4e4;border-top: 0;width: 100%;font-size: 11px;color: #585963;table-layout: fixed;}
.rate-table thead th {background: #f5f5f5;color: #585963;}
.rate-table th, .rate-table td {height: 30px;background: #f5f5f5;text-shadow: none;}
.rate-table th {background: #525976;color: #fff;border: 1px solid #c8d4e4;}
.rate-table tr:nth-child(odd) td {background: #fff;}
.rate-table .s-btn {border-radius: 2px;border: solid 1px #000;background-color: #252930;color: #fff;padding: 3px 10px;}

.onlyNum {position: relative;}
#newPoint, #newCash {
  width: 110px;
  height: 20px;
  color: transparent;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
#newPoint::selection, #newCash::selection {
  background:none;
}
#newPoint+label, #newCash+label {
  font-size: 12px;
  background: #fff;
  color: #000;
  height: 20px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 110px;
  position: absolute;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
